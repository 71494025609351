import { combineReducers } from "redux";
import { loginReducer } from "./onboardingReducer";
import { utilReducer } from "./utilReducer";
import tradeNameReducer from "./editTradeNameReducer";
import accountLimitReducer from "./accountLimitReducer";
import ConnectK4bAccountReducer from "./connectK4bAccountReducer";
import { businessTransactionsReducer } from "./businessTransactionsReducer";
import { customerReducer } from "./customersReducer";
import { modalsReducer } from "./modalsReducer";
import GoLiveReducer from "./goLiveReducer";
import TestConsoleReducer from "./testConsoleReducer";
import WebhooksReducer from "./webhooksReducer";
import UpdateUserReducer from "./updateUserReducer";
import { errorHandlerReducer } from "./errorHandlerReducer";
import { cardsReducer } from "./cardsReducer";
import { UploadReducer } from "./UploadReducer";

const rootReducer = combineReducers({
  login: loginReducer,
  util: utilReducer,
  businessTransactions: businessTransactionsReducer,
  customerTransactions: customerReducer,
  modals: modalsReducer,
  tradeName: tradeNameReducer,
  accountLimit: accountLimitReducer,
  connectK4bAccount: ConnectK4bAccountReducer,
  golive: GoLiveReducer,
  testConsole: TestConsoleReducer,
  webhooks: WebhooksReducer,
  updateUser: UpdateUserReducer,
  errorHandler: errorHandlerReducer,
  cards: cardsReducer,
  uploadDocument: UploadReducer
});

export default rootReducer;
