import * as types from "../actions/actionTypes";

const initialState = {
    data: {},
    message: "",
    error: {},
    loading: false,
    status: false
}

export default function ConnectK4bAccountReducer (state = initialState, action) {
    switch (action.type) {
      case types.CONNECT_KUDA_ACCOUNT_API_CALL_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
     case types.CONNECT_KUDA_ACCOUNT_API_CALL_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.error
      };
     case types.CONNECT_KUDA_ACCOUNT_SUCCESS:
      return {
        ...state,
        data: action.data,
        status: action.status,
        message: action.message,
        loading: false
      };
        default:
            return state
    }
}