import * as types from "../actions/actionTypes";

const initialState = {
    getTransactionLimit: {
        data: {},
        message: {},
        loading: false,
        status: false
    },
    setTransactionLimit: {
        data: {},
        message: {},
        loading: false,
        status: false
    }
}

export default function accountLimitReducer (state = initialState, action) {
    switch (action.type) {
        case types.GET_TRANSACTION_LIMIT_INPROGRESS:
            return {
                ...state,
                getTransactionLimit: {
                    loading: true
                }
            }
        case types.GET_TRANSACTION_LIMIT_FAILURE:
            return {
                ...state,
                getTransactionLimit: {
                    loading: false,
                    message: action.error,
                    status: action.status
                }
            }
        case types.GET_TRANSACTION_LIMIT_SUCCESS:
            return {
                ...state,
                getTransactionLimit: {
                    loading: false,
                    message: action.error,
                    status: action.status,
                    data: action.data
                }
            }
        case types.SET_TRANSACTION_LIMIT_INPROGRESS:
            return {
                ...state,
                setTransactionLimit: {
                    loading: true
                }
            }
        case types.SET_TRANSACTION_LIMIT_FAILURE:
            return {
                ...state,
                setTransactionLimit: {
                    loading: false,
                    message: action.error,
                    status: action.status
                }
            }
        case types.SET_TRANSACTION_LIMIT_SUCCESS:
            return {
                ...state,
                setTransactionLimit: {
                    loading: false,
                    data: action.data,
                    status: action.status,
                    message: action.message
                }
            }
        default:
            return state
    }
}