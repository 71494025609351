import React from "react";
import { ErrorContainer } from "./ErrorStyles";
import kudaLogo from "../../assets/dashboardlayout/kuda-logo.svg";

const GeneralError = () => {

  const handleGoBack = () => {
    window.location.reload();
  };
  return (
    <ErrorContainer>
      <div className="error__top-section">
        <img src={kudaLogo} alt="whitekuda logo" />
      </div>
      <div className="error__body-section">
        <h5>Something went wrong</h5>
        <button onClick={handleGoBack}>Try Again</button>
      </div>
    </ErrorContainer>
  );
};

export default GeneralError;
