import * as types from "../actions/actionTypes";

const initialState = {
  subaccounts: [],
  subaccountsRevenue: 0,
  subaccountsCreated: 0,
  subaccountTransactions: [],
  subaccountInflow: [],
  subaccountOutflow: [],
  transactionsRecordsInStore: 0,
  subAccountsInStore: 0,
  inflowRecordsInStore: 0,
  outflowRecordsInStore: 0,
  recordsInStore: 0,
  status: false,
  loading: false,
  message: "",
  createsubMessage: "",
  data: {},
  error: {},
};

export const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SUBACCOUNTS_API_CALL_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_SUBACCOUNTS_API_CALL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.GET_SUBACCOUNTS_SUCCESS:
      return {
        ...state,
        subaccounts: action.data,
        subAccountsInStore: action.recordsInStore,
        loading: false,
        status: action.status,
        message: action.message,
      };
    case types.GET_SUBACCOUNT_TRANSACTIONS_API_CALL_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_SUBACCOUNT_TRANSACTIONS_API_CALL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.GET_SUBACCOUNT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        recordsInStore: action.recordsInStore,
        subaccountTransactions: action.data,
        loading: false,
      };
    case types.GET_SUBACCOUNT_INFLOW_API_CALL_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_SUBACCOUNT_INFLOW_API_CALL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.GET_SUBACCOUNT_INFLOW_SUCCESS:
      return {
        ...state,
        subaccountInflow: action.data,
        inflowRecordsInStore: action.recordsInStore,
        loading: false,
      };
    case types.GET_SUBACCOUNT_OUTFLOW_API_CALL_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_SUBACCOUNT_OUTFLOW_API_CALL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.GET_SUBACCOUNT_OUTFLOW_SUCCESS:
      return {
        ...state,
        subaccountOutflow: action.data,
        outflowRecordsInStore: action.recordsInStore,
        loading: false,
      };
    case types.GET_FILTERED_SUBACCOUNT_TRANSACTIONS_API_CALL_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_FILTERED_SUBACCOUNT_TRANSACTIONS_API_CALL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.GET_FILTERED_SUBACCOUNT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        subaccountTransactions: action.data,
        recordsInStore: action.totalRecordsInStore,
      };
    case types.CREATE_SUBACCOUNT_API_CALL_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_SUBACCOUNT_API_CALL_FAILURE:
      return {
        ...state,
        loading: false,
        status: action.status,
        createsubMessage: action.error.message,
      };
    case types.CREATE_SUBACCOUNT_SUCCESS:
      return {
        ...state,
        data: action.data,
        status: action.status,
        createsubMessage: action.message,
        loading: false,
      };
    case types.GET_SUBACCOUNTS_REVENUE_API_CALL_INPROGRESS:
      return {
        ...state,
        loading: false,
      };
    case types.GET_SUBACCOUNTS_REVENUE_API_CALL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.GET_SUBACCOUNTS_REVENUE_SUCCESS:
      return {
        ...state,
        subaccountsRevenue: action.data,
        loading: false,
      };
    case types.GET_SUBACCOUNTS_CREATED_API_CALL_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_SUBACCOUNTS_CREATED_API_CALL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.GET_SUBACCOUNTS_CREATED_SUCCESS:
      return {
        ...state,
        subaccountsCreated: action.data,
        loading: false,
      };
    default:
      return state;
  }
};
