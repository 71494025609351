import styled from "styled-components";

export const ErrorContainer = styled.section`
  width: 100%;

  .error__top-section {
    width: 100%;
    height: 70px;
    background: #40196d;
    padding-left: 32px;
    display: flex;
    align-items: center;
  }
  .error__body-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 70px);
    .error__icon {
      margin-bottom: 36px;
    }
    h5 {
      font-weight: 900;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      color: #121212;
    }
    button {
      background: #40196d;
      border-radius: 10px;
      height: 48px;
      width: 183px;
      border-radius: 10px;
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;
      text-align: center;
      color: #ffffff;
      margin-top: 36px;
      cursor: pointer;
    }
  }
`;
