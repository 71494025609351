import * as types from "../actions/actionTypes";

const initialState = {
  loading: false,
  status: false,
  message: "",
  data: "",
};

export const UploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DOCUMENTS_UPLOAD_API_CALL_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case types.DOCUMENTS_UPLOAD_API_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.DOCUMENTS_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.status,
        message: action.message,
      };
    case types.GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.status,
        data: action.data,
      };
    default:
      return state;
  }
};
