import React from "react";
import styled from "styled-components";

const PreLoader = () => {
  return (
    <LoaderContainer>
      <span className="spinner"></span>
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default PreLoader;
