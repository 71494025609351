import * as types from "../actions/actionTypes";

const initiialState = {
  businessAccountTransaction: [],
  filteredBusinessAccountTransaction: [],
  mainAccountBalance: 0,
  loading: false,
  recordsInStore: 0,
};

export const businessTransactionsReducer = (state = initiialState, action) => {
  switch (action.type) {
    case types.GET_MAINACCOUNT_TRANSACTIONS_API_CALL_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_MAINACCOUNT_TRANSACTIONS_API_CALL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.GET_MAINACCOUNT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        businessAccountTransaction: action.data,
        recordsInStore: action.recordsInStore,
        loading: false,
      };
    case types.GET_FILTERED_MAINACCOUNT_TRANSACTIONS_API_CALL_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_FILTERED_MAINACCOUNT_TRANSACTIONS_API_CALL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.GET_FILTERED_MAINACCOUNT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        filteredBusinessAccountTransaction: action.data,
        recordsInStore: action.recordsInStore,
        loading: false,
      };
    case types.GET_ACCOUNT_BALANCE_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ACCOUNT_BALANCE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.GET_ACCOUNT_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        mainAccountBalance: action.data,
      };
    default:
      return state;
  }
};
