import * as types from "../actions/actionTypes";

const initialState = {
    data: {},
    loading: false,
    status: false,
    message: ""
}

export default function GoLiveReducer (state = initialState, action) {
    switch (action.type) {
        case types.GO_LIVE_REQUEST_API_CALL_INPROGRESS:
            return {
                ...state,
                loading: true
            }
        case types.GO_LIVE_REQUEST_API_CALL_FAILURE:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: false
            }
        case types.GO_LIVE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.message,
                data: action.data,
                status: action.status
            }
        default:
            return state;
    }
}