import React, { Component } from 'react';
import GeneralError from '../error/GeneralError';

export default class ErrorBoundary extends Component {
  state = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    if (!this.state.errorInfo) {
      return this.props.children;
    }
    return <GeneralError />
  }
}
