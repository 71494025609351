import * as types from '../actions/actionTypes';

const initialState = {
  isOnLive: false,
  clientStatus: 1,
  accountNumber: "",
  isTINUploaded: false,
  isCACUploaded: false,
  isLiveStatus: false,
  message: "",
};

export default function UpdateUserReducer (state = initialState, action) {
    switch (action.type) {
    case types.GET_ACCOUNT_DATA:
      return {
        ...state,
        isOnLive: action.isOnLive,
        clientStatus: action.clientStatus,
        accountNumber: action.accountNumber,
        isTINUploaded: action.isTINUploaded,
        isCACUploaded: action.isCACUploaded
      }
    case types.UPDATE_ACCOUNT_DATA_IN_PROGESS:
      return {
        ...state,
        loading: true,
      }
    case types.UPDATE_ACCOUNT_DATA:
      return {
        ...state,
        loading: false,
        message: action.message,
        isLiveStatus: false,
      };
      case types.UPDATE_ACCOUNT_DATA_FAILURE:
        return {
          ...state,
          loading: false,
          message: action.message,
          isLiveStatus: false,
        };
        default:
            return state
    }
}