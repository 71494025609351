import { base64ToHex } from '../utils/utilityFunctions';

export const rsaEncryption = (password) => {
  const crypto = require('crypto');
  const publicKeyString = `${process.env.REACT_APP_PUBLIC_KEY}`;
  let key = {
    key: publicKeyString,
    padding: crypto.constants.RSA_PKCS1_PADDING,
  };
  let encryptedValue = crypto
    .publicEncrypt(key, Buffer.from(password))
    .toString('base64');
  return encryptedValue;
};

export const aesEncryption = (data, key) => {
  var CryptoJS = require('crypto-js');
  var keySize = 256;
  var iterations = 1000;
  var salt = `${process.env.REACT_APP_PFD}`;
  var aesKey = CryptoJS.PBKDF2(key, salt, {
    keySize: keySize / 32,
    iterations: iterations,
  });

  var iv = CryptoJS.PBKDF2(key, salt, {
    keySize: keySize / 64,
    iterations: iterations,
  });

  var encrypted = CryptoJS.AES.encrypt(data, aesKey, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  var encryptedHex = base64ToHex(encrypted.toString());
  var base64String = Buffer.from(encryptedHex, 'hex').toString('base64');
  return base64String;
};

export const aesDecryption = (data, key) => {
  var CryptoJS = require('crypto-js');
  var keySize = 256;
  var iterations = 1000;
  var salt = `${process.env.REACT_APP_PFD}`;

  var aesKey = CryptoJS.PBKDF2(key, salt, {
    keySize: keySize / 32,
    iterations: iterations,
  });

  var iv = CryptoJS.PBKDF2(key, salt, {
    keySize: keySize / 64,
    iterations: iterations,
  });

  var decrypted = CryptoJS.AES.decrypt(data, aesKey, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  decrypted = decrypted.toString(CryptoJS.enc.Utf8);
  return decrypted;
};
