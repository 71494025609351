import * as types from "../actions/actionTypes";

const initialState = {
  showErrorModal: false,
  errorType: null,
  message: "",
  errorCode: null,
};
export const errorHandlerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.HTTP_401_ERROR:
      return {
        ...state,
        showErrorModal: true,
        message: action.message,
        errorCode: action.errorCode
      };
    case types.HTTP_500_ERROR:
      return {
        ...state,
        showErrorModal: true,
        message: action.message,
        errorCode: action.errorCode,
      };
    case types.HTTP_OTHER_ERROR:
      return {
        ...state,
        showErrorModal: true,
        message: action.message,
        errorCode: action.errorCode,
      };
    default:
      return state;
  }
};
