import * as types from "../actions/actionTypes";

const initialState = {
  bankListData: [],
  nameEnquiryData: [],
  createVirtualAccountData: [],
  retrieveVirtualAccountData: [],
  singleFundTransferData: [],
  virtualAccountFundTransferData: [],
  transactionStatusQueryData: [],
  retreiveVirtualAccountBalanceData: [],
  retreiveTransactionLogsData: [],
  mainAccountTransactionsData: [],
  mainAccountFilteredTransactionsData: [],
  virtualAccountTransactionsData: [],
  virtualAccountFilteredTransactionsData: [],
  fundVirtualAccountData: [],
  withdrawVirtualAccountData: [],
  retreiveMainAccountBalanceData: [],
  retrieveSpendAndSaveTransactionsData: [],
  createPlainSavingsData: [],
  getPlainSavingsData: [],
  getAllCustomerPlainSavingsData: [],
  getAllPlainSavingsData: [],
  updatePlainSavingsData: [],
  plainSavingsTransactionsData: [],
  retrievePlainSavingsTransactionsData: [],
  createOpenFlexibleSaveData: [],
  createClosedFlexibleSaveData: [],
  retrieveFlexibleSaveData: [],
  retrieveAllCustomerFlexibleSaveData: [],
  retrieveAllFlexibleSaveData: [],
  withdrawFromFlexibleSaveData: [],
  retrieveAllFlexibleSaveTransactionsData: [],
  createFixedSavingsData: [],
  getFixedSavingsData: [],
  getAllCustomerFixedSavingsData: [],
  getAllFixedSavingsData: [],
  completeFixedSavingsWithdrawalData: [],
  retrieveFixedSavingsTransactionsData: []
}

export default function TestConsoleReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.PUBLISH_TO_TEST_CONSOLE_API_CALL_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case types.PUBLISH_TO_TEST_CONSOLE_API_CALL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.PUBLISH_TO_TEST_CONSOLE_SUCCESS:
      return switchServiceType(state, action)
    case types.RESET_TEST_CONSOLE:
      return {
        results: [],
      };
    default:
      return state;
  }
}

function switchServiceType (state, action) {
  switch (action.serviceType) {
    case "BANK_LIST":
        return {
          ...state,
          bankListData: action.data,
          loading: false,
        };
    case "NAME_ENQUIRY":
        return {
          ...state,
          nameEnquiryData: action.data,
          loading: false,
        };
    case "RETRIEVE_VIRTUAL_ACCOUNT_BALANCE":
      return {
        ...state,
        retreiveVirtualAccountBalanceData: action.data,
        loading: false,
      };
    case "SINGLE_FUND_TRANSFER":
      return {
        ...state,
        singleFundTransferData: action.data,
        loading: false,
      };
    case "TRANSACTION_STATUS_QUERY":
      return {
        ...state,
        transactionStatusQueryData: action.data,
        loading: false,
      };
    case "ADMIN_CREATE_VIRTUAL_ACCOUNT":
      return {
        ...state,
        createVirtualAccountData: action.data,
        loading: false,
      };
    case "ADMIN_RETRIEVE_SINGLE_VIRTUAL_ACCOUNT":
      return {
        ...state,
        retrieveVirtualAccountData: action.data,
        loading: false,
      };
    case "VIRTUAL_ACCOUNT_FUND_TRANSFER":
      return {
        ...state,
        virtualAccountFundTransferData: action.data,
        loading: false,
      };
    case "RETRIEVE_TRANSACTION_LOGS":
      return {
        ...state,
        retreiveTransactionLogsData: action.data,
        loading: false,
      };
    case "ADMIN_MAIN_ACCOUNT_TRANSACTIONS":
      return {
        ...state,
        mainAccountTransactionsData: action.data,
        loading: false,
      }
    case "ADMIN_MAIN_ACCOUNT_FILTERED_TRANSACTIONS":
      return {
        ...state,
        mainAccountFilteredTransactionsData: action.data,
        loading: false,
      }
    case "ADMIN_VIRTUAL_ACCOUNT_TRANSACTIONS":
      return {
        ...state,
        virtualAccountTransactionsData: action.data,
        loading: false,
      }
    case "ADMIN_VIRTUAL_ACCOUNT_FILTERED_TRANSACTIONS":
      return {
        ...state,
        virtualAccountFilteredTransactionsData: action.data,
        loading: false,
      }
    case "FUND_VIRTUAL_ACCOUNT":
      return {
        ...state,
        fundVirtualAccountData: action.data,
        loading: false,
      }
    case "WITHDRAW_VIRTUAL_ACCOUNT":
      return {
        ...state,
        withdrawVirtualAccountData: action.data,
        loading: false,
      }
    case "ADMIN_RETRIEVE_MAIN_ACCOUNT_BALANCE":
      return {
        ...state,
        retreiveMainAccountBalanceData: action.data,
        loading: false,
      }
    case "RETRIEVE_SPEND_AND_SAVE_TRANSACTIONS":
      return {
        ...state,
        retrieveSpendAndSaveTransactionsData: action.data,
        loading: false,
      }
    case "CREATE_PLAIN_SAVINGS": 
      return {
        ...state,
        createPlainSavingsData: action.data,
        loading: false,
      }
    case "GET_PLAIN_SAVINGS":
      return {
        ...state,
        getAllCustomerPlainSavingsData: action.data,
        loading: false,
      }
    case "GET_ALL_PLAIN_SAVINGS":
      return {
        ...state,
        getAllPlainSavingsData: action.data,
        loading: false,
      }
    case "GET_ALL_CUSTOMER_PLAIN_SAVINGS":
      return {
        ...state,
        getPlainSavingsData: action.data,
        loading: false,
      }
    case "UPDATE_PLAIN_SAVINGS": 
      return {
        ...state,
        updatePlainSavingsData: action.data,
        loading: false,
      }
    case "PLAIN_SAVINGS_TRANSACTIONS":
      return {
        ...state,
        plainSavingsTransactionsData: action.data,
        loading: false,
      }
    case "RETRIEVE_PLAIN_SAVINGS_TRANSACTIONS":
      return {
        ...state,
        retrievePlainSavingsTransactionsData: action.data,
        loading: false,
      }
    case "CREATE_OPEN_FLEXIBLE_SAVE":
      return {
        ...state,
        createOpenFlexibleSaveData: action.data,
        loading: false,
      }
    case "CREATE_CLOSED_FLEXIBLE_SAVE":
      return {
        ...state,
        createClosedFlexibleSaveData: action.data,
        loading: false,
      }
    case "GET_OPEN_FLEXIBLE_SAVE":
    case "GET_CLOSED_FLEXIBLE_SAVE":
      return {
        ...state,
        retrieveFlexibleSaveData: action.data,
        loading: false,
      }
    case "GET_ALL_CUSTOMER_OPEN_FLEXIBLE_SAVE":
    case "GET_ALL_CUSTOMER_CLOSED_FLEXIBLE_SAVE":
      return {
        ...state,
        retrieveAllCustomerFlexibleSaveData: action.data,
        loading: false,
      }
    case "GET_ALL_OPEN_FLEXIBLE_SAVE":
    case "GET_ALL_CLOSED_FLEXIBLE_SAVE":
      return {
        ...state,
        retrieveAllFlexibleSaveData: action.data,
        loading: false,
      }
    case "COMPLETE_OPEN_FLEXIBLE_SAVE_WITHDRAWAL":
    case "COMPLETE_CLOSED_FLEXIBLE_SAVE_WITHDRAWAL":
      return {
        ...state,
        withdrawFromFlexibleSaveData: action.data,
        loading: false,
      }
    case "RETRIEVE_OPEN_FLEXIBLE_SAVINGS_TRANSACTIONS":
    case "RETRIEVE_CLOSED_FLEXIBLE_SAVINGS_TRANSACTIONS":
      return {
        ...state,
        retrieveAllFlexibleSaveTransactionsData: action.data,
        loading: false,
      }
    case "CREATE_FIXED_SAVINGS":
      return {
        ...state,
        createFixedSavingsData: action.data,
        loading: false,
      }
    case "GET_FIXED_SAVINGS":
      return {
        ...state,
        getFixedSavingsData: action.data,
        loading: false,
      }
    case "GET_ALL_CUSTOMER_FIXED_SAVINGS":
      return {
        ...state,
        getAllCustomerFixedSavingsData: action.data,
        loading: false,
      }
    case "GET_ALL_FIXED_SAVINGS":
      return {
        ...state,
        getAllFixedSavingsData: action.data,
        loading: false,
      }
    case "COMPLETE_FIXED_SAVINGS_WITHDRAWAL":
      return {
        ...state,
        completeFixedSavingsWithdrawalData: action.data,
        loading: false,
      }
    case "RETRIEVE_FIXED_SAVINGS_TRANSACTIONS":
      return {
        ...state,
        retrieveFixedSavingsTransactionsData: action.data,
        loading: false,
      }
    default:
      break;
  }
}