import * as types from "../actions/actionTypes";

const initialState = {
  checkCardAccessPermission: {},
  loading: false,
  status: false,
  reviewStatus: null,
  message: "",
  data: [],
};

export const cardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CARD_START:
      return {
        ...state,
        loading: true,
      };
    case types.CARD_FAIL:
      return {
        ...state,
        loading: false,
      };
    case types.CHECK_CARD_PERMISSION:
      return {
        ...state,
        loading: false,
        status: action.status,
        message: action.message,
      };
    case types.CHECK_CARD_APPROVAL_STATUS:
      return {
        ...state,
        loading: false,
        reviewStatus: action.status,
        message: action.message,
      };
    case types.REQUEST_CARD:
      return {
        ...state,
        loading: false,
        status: action.status,
        message: action.message,
      };
    case types.FETCH_CARDS:
      return {
        ...state,
        loading: false,
        status: action.status,
        message: action.message,
        data: action.data,
      };
    default:
      return state;
  }
};
