// onboarding Actions
export const REGISTER_INPROGRESS = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const UPDATE_CLIENT_INPROGRESS = "UPDATE_CLIENT_INPROGRESS";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAILURE = "UPDATE_CLIENT_FAILURE";

export const LOGIN_INPROGRESS = "LOGIN_INPROGRESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const RESET_LOGIN_STATE = "RESET_LOGIN_STATE";

export const LOGIN_SEND_OTP_INPROGRESS = "LOGIN_SEND_OTP_INPROGRESS";
export const LOGIN_SEND_OTP_FAILURE = "LOGIN_SEND_OTP_FAILURE";
export const LOGIN_SEND_OTP_SUCCESS = "LOGIN_SEND_OTP_SUCCESS";

export const LOGIN_VALIDATE_OTP_INPROGRESS = "LOGIN_VALIDATE_OTP_INPROGRESS";
export const LOGIN_VALIDATE_OTP_FAILURE = "LOGIN_VALIDATE_OTP_FAILURE";
export const LOGIN_VALIDATE_OTP_SUCCESS = "LOGIN_VALIDATE_OTP_SUCCESS";

export const SIGN_OUT = "SIGN_OUT";

export const MENU_TOGGLE = "MENU_TOGGLE";

// DOCUMENTS_UPLOAD ACTIONS
export const DOCUMENTS_UPLOAD_API_CALL_INPROGRESS =
  "DOCUMENTS_UPLOAD_API_CALL_INPROGRESS";
export const DOCUMENTS_UPLOAD_API_FAILURE = "DOCUMENTS_UPLOAD_API_FAILURE";
export const DOCUMENTS_UPLOAD_SUCCESS = "DOCUMENTS_UPLOADED_SUCCESS";
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";

// CONNECT_KUDA_ACCOUNT ACTIONS
export const CONNECT_KUDA_ACCOUNT_API_CALL_INPROGRESS =
  "CONNECT_KUDA_ACCOUNT_API_CALL_INPROGRESS";
export const CONNECT_KUDA_ACCOUNT_API_CALL_FAILURE =
  "CONNECT_KUDA_ACCOUNT_API_CALL_FAILURE";
export const CONNECT_KUDA_ACCOUNT_SUCCESS = "CONNECT_KUDA_ACCOUNT_SUCCESS";

// ACCOUNT_STATUS ACTIONS
export const GET_ACCOUNT_DATA = "GET_ACCOUNT_DATA";
export const UPDATE_ACCOUNT_DATA = "UPDATE_ACCOUNT_DATA";
export const UPDATE_ACCOUNT_DATA_FAILURE = "UPDATE_ACCOUNT_DATA_FAILURE";
export const UPDATE_ACCOUNT_DATA_IN_PROGESS = "UPDATE_ACCOUNT_DATA_IN_PROGESS";

// GET_API_KEYS ACTIONS
export const REGENERATE_KEYS_API_CALL_INPROGRESS =
  "REGENERATE_KEYS_API_CALL_INPROGRESS";
export const REGENERATE_KEYS_API_CALL_FAILURE =
  "REGENERATE_KEYS_API_CALL_FAILURE";
export const REGENERATE_KEYS_API_SUCCESS = "REGENERATE_KEYS_API_CALL_SUCCESS";

// GO_LIVE_REQUEST ACTIONS
export const GO_LIVE_REQUEST_API_CALL_INPROGRESS =
  "GO_LIVE_REQUEST_API_CALL_INPROGRESS";
export const GO_LIVE_REQUEST_API_CALL_FAILURE =
  "GO_LIVE_REQUEST_API_CALL_FAILURE";
export const GO_LIVE_REQUEST_SUCCESS = "GO_LIVE_REQUEST_SUCCESS";

export const PUBLISH_TO_TEST_CONSOLE_API_CALL_INPROGRESS =
  "PUBLISH_TO_TEST_CONSOLE_API_CALL_INPROGRESS";
export const PUBLISH_TO_TEST_CONSOLE_API_CALL_FAILURE =
  "PUBLISH_TO_TEST_CONSOLE_API_CALL_FAILURE";
export const PUBLISH_TO_TEST_CONSOLE_SUCCESS =
  "PUBLISH_TO_TEST_CONSOLE_SUCCESS";
export const RESET_TEST_CONSOLE = "RESET_TEST_CONSOLE";

export const UPDATE_TEST_WEBHOOK_URL_API_CALL_INPROGRESS =
  "UPDATE_TEST_WEBHOOK_URL_API_CALL_INPROGRESS";
export const UPDATE_TEST_WEBHOOK_URL_API_CALL_SUCCESS =
  "UPDATE_TEST_WEBHOOK_URL_API_CALL_SUCCESS";
export const UPDATE_TEST_WEBHOOK_URL_API_CALL_FAILURE =
  "UPDATE_TEST_WEBHOOK_URL_API_CALL_FAILURE";

export const UPDATE_LIVE_WEBHOOK_URL_API_CALL_INPROGRESS =
  "UPDATE_LIVE_WEBHOOK_URL_API_CALL_INPROGRESS";
export const UPDATE_LIVE_WEBHOOK_URL_API_CALL_SUCCESS =
  "UPDATE_LIVE_WEBHOOK_URL_API_CALL_SUCCESS";
export const UPDATE_LIVE_WEBHOOK_URL_API_CALL_FAILURE =
  "UPDATE_LIVE_WEBHOOK_URL_API_CALL_FAILURE";

export const UPDATE_USER_TO_LIVE_OR_TEST_INPROGRESS =
  "UPDATE_USER_TO_LIVE_OR_TEST_INPROGRESS";
export const UPDATE_USER_TO_LIVE_OR_TEST_SUCCESS =
  "UPDATE_USER_TO_LIVE_OR_TEST_SUCCESS";
export const UPDATE_USER_TO_LIVE_OR_TEST_FAILURE =
  "UPDATE_USER_TO_LIVE_OR_TEST_INPROGRESS";

export const GET_TRANSACTION_LIMIT_INPROGRESS =
  "GET_TRANSACTION_LIMIT_INPROGRESS";
export const GET_TRANSACTION_LIMIT_FAILURE = "GET_TRANSACTION_LIMIT_FAILURE";
export const GET_TRANSACTION_LIMIT_SUCCESS = "GET_TRANSACTION_LIMIT_SUCCESS";

export const SET_TRANSACTION_LIMIT_INPROGRESS =
  "SET_TRANSACTION_LIMIT_INPROGRESS";
export const SET_TRANSACTION_LIMIT_FAILURE = "SET_TRANSACTION_LIMIT_FAILURE";
export const SET_TRANSACTION_LIMIT_SUCCESS = "SET_TRANSACTION_LIMIT_SUCCESS";

export const GET_ACCOUNT_STATEMENT_IN_PROGRESS =
  "GET_ACCOUNT_STATEMENT_IN_PROGRESS";
export const GET_ACCOUNT_STATEMENT_SUCCESS = "GET_ACCOUNT_STATEMENT_SUCCES";
export const GET_ACCOUNT_STATEMENT_FAILURE = "GET_ACCOUNT_STATEMENT_FAILURE";

export const EDIT_TRADE_NAME_IN_PROGRESS = "EDIT_TRADE_NAME_IN_PROGRESS";
export const EDIT_TRADE_NAME_SUCCESS = "EDIT_TRADE_NAME_SUCCESS";
export const EDIT_TRADE_NAME_FAILURE = "EDIT_TRADE_NAME_FAILURE";

export const EDIT_ACCOUNT_NAME_IN_PROGRESS = "EDIT_ACCOUNT_NAME_IN_PROGRESS";
export const EDIT_ACCOUNT_NAME_SUCCESS = "EDIT_ACCOUNT_NAME_SUCCESS";
export const EDIT_ACCOUNT_NAME_FAILURE = "EDIT_ACCOUNT_NAME_FAILURE";
// GET_MAINACCOUNT_TRANSACTIONS ACTIONS
export const GET_MAINACCOUNT_TRANSACTIONS_API_CALL_INPROGRESS =
  "GET_MAINACCOUNT_TRANSACTIONS_API_CALL_INPROGRESS";
export const GET_MAINACCOUNT_TRANSACTIONS_API_CALL_FAILURE =
  "GET_MAINACCOUNT_TRANSACTIONS_API_CALL_FAILURE";
export const GET_MAINACCOUNT_TRANSACTIONS_SUCCESS =
  "GET_MAINACCOUNT_TRANSACTIONS_SUCCESS";

// GET_FILTERED_MAINACCOUNT_TRANSACTIONS ACTIONS
export const GET_FILTERED_MAINACCOUNT_TRANSACTIONS_API_CALL_INPROGRESS =
  "GET_FILTERED_MAINACCOUNT_TRANSACTIONS_API_CALL_INPROGRESS";
export const GET_FILTERED_MAINACCOUNT_TRANSACTIONS_API_CALL_FAILURE =
  "GET_FILTERED_MAINACCOUNT_TRANSACTIONS_API_CALL_FAILURE";
export const GET_FILTERED_MAINACCOUNT_TRANSACTIONS_SUCCESS =
  "GET_FILTERED_MAINACCOUNT_TRANSACTIONS_SUCCESS";

// SUBACCOUNT CREATED ACTIONS
export const GET_SUBACCOUNTS_CREATED_API_CALL_INPROGRESS =
  "GET_SUBACCOUNTS_CREATED_API_CALL_INPROGRESS";

export const GET_SUBACCOUNTS_CREATED_API_CALL_FAILURE =
  "GET_SUBACCOUNTS_CREATED_API_CALL_FAILURE";
export const GET_SUBACCOUNTS_CREATED_SUCCESS =
  "GET_SUBACCOUNTS_CREATED_SUCCESS";

// SUBACCOUNT REVENUE ACTIONS
export const GET_SUBACCOUNTS_REVENUE_API_CALL_INPROGRESS =
  "GET_SUBACCOUNTS_REVENUE_API_CALL_INPROGRESS";
export const GET_SUBACCOUNTS_REVENUE_API_CALL_FAILURE =
  "GET_SUBACCOUNTS_REVENUE_API_CALL_FAILURE";
export const GET_SUBACCOUNTS_REVENUE_SUCCESS =
  "GET_SUBACCOUNTS_REVENUE_SUCCESS";

// GET_SUBACCOUNT_INFLOW ACTIONS
export const GET_SUBACCOUNT_INFLOW_API_CALL_INPROGRESS =
  "GET_SUBACCOUNT_INFLOW_API_CALL_INPROGRESS";
export const GET_SUBACCOUNT_INFLOW_API_CALL_FAILURE =
  "GET_SUBACCOUNT_INFLOW_API_CALL_FAILURE";
export const GET_SUBACCOUNT_INFLOW_SUCCESS = "GET_SUBACCOUNT_INFLOW_SUCCESS";

// GET_SUBACCOUNT_OUTFLOW ACTIONS
export const GET_SUBACCOUNT_OUTFLOW_API_CALL_INPROGRESS =
  "GET_SUBACCOUNT_OUTFLOW_API_CALL_INPROGRESS";
export const GET_SUBACCOUNT_OUTFLOW_API_CALL_FAILURE =
  "GET_SUBACCOUNT_OUTFLOW_API_CALL_FAILURE";
export const GET_SUBACCOUNT_OUTFLOW_SUCCESS = "GET_SUBACCOUNT_OUTFLOW_SUCCESS";

// GET_SUBACCOUNTS ACTIONS
export const GET_SUBACCOUNTS_API_CALL_INPROGRESS =
  "GET_SUBACCOUNTS_API_CALL_INPROGRESS";
export const GET_SUBACCOUNTS_API_CALL_FAILURE =
  "GET_SUBACCOUNTS_API_CALL_FAILURE";
export const GET_SUBACCOUNTS_SUCCESS = "GET_SUBACCOUNTS_SUCCESS";

// GET_SUBACCOUNT_TRANSACTIONS ACTIONS
export const GET_SUBACCOUNT_TRANSACTIONS_API_CALL_INPROGRESS =
  "GET_SUBACCOUNT_TRANSACTIONS_API_CALL_INPROGRESS";
export const GET_SUBACCOUNT_TRANSACTIONS_API_CALL_FAILURE =
  "GET_SUBACCOUNT_TRANSACTIONS_API_CALL_FAILURE";
export const GET_SUBACCOUNT_TRANSACTIONS_SUCCESS =
  "GET_SUBACCOUNT_TRANSACTIONS_SUCCESS";

// GET_FILTERED_SUBACCOUNT_TRANSACTIONS ACTIONS
export const GET_FILTERED_SUBACCOUNT_TRANSACTIONS_API_CALL_INPROGRESS =
  "GET_FILTERED_SUBACCOUNT_TRANSACTIONS_API_CALL_INPROGRESS";
export const GET_FILTERED_SUBACCOUNT_TRANSACTIONS_API_CALL_FAILURE =
  "GET_FILTERED_SUBACCOUNT_TRANSACTIONS_API_CALL_FAILURE";
export const GET_FILTERED_SUBACCOUNT_TRANSACTIONS_SUCCESS =
  "GET_FILTERED_SUBACCOUNT_TRANSACTIONS_SUCCESS";

// MODAL_ACTIONS
export const OPEN_CONNECT_K4B_MODAL = "OPEN_CONNECT_K4B_MODAL";
export const OPEN_CONNECTING_K4B_MODAL = "OPEN_CONNECTING_K4B_MODAL";
export const OPEN_GO_LIVE_REQUEST = "OPEN_GO_LIVE_REQUEST";
export const OPEN_FEEDBACK_MODAL = "OPEN_FEEDBACK_MODAL";

// CREATE_SUBACCOUNT ACTIONS
export const CREATE_SUBACCOUNT_API_CALL_INPROGRESS =
  "CREATE_SUBACCOUNT_API_CALL_INPROGRESS";
export const CREATE_SUBACCOUNT_API_CALL_FAILURE =
  "CREATE_SUBACCOUNT_API_CALL_FAILURE";
export const CREATE_SUBACCOUNT_SUCCESS = "CREATE_SUBACCOUNT_SUCCESS";

// main account balance
export const GET_ACCOUNT_BALANCE_INPROGRESS = "GET_ACCOUNT_BALANCE_INPROGRESS";
export const GET_ACCOUNT_BALANCE_SUCCESS = "GET_ACCOUNT_BALANCE_SUCCESS";
export const GET_ACCOUNT_BALANCE_FAILURE = "GET_ACCOUNT_BALANCE_FAILURE";

// Error actions
export const HTTP_500_ERROR = "HTTP_500_ERROR";
export const HTTP_401_ERROR = "HTTP_401_ERROR";
export const HTTP_OTHER_ERROR = "HTTP_OTHER_ERROR";

// card actions
export const CARD_START = "CARD_START";
export const CARD_FAIL = "CARD_FAIL";
export const CARD_SUCCESS = "CARD_SUCCESS";

export const CHECK_CARD_PERMISSION = "CHECK_CARD_PERMISSION";
export const CHECK_CARD_APPROVAL_STATUS = "CHECK_CARD_APPROVAL_STATUS";
export const REQUEST_CARD = "REQUEST_CARD";
export const FETCH_CARDS = "FETCH_CARDS";
