import * as types from "../actions/actionTypes";

const initialState = {
  menuOpen: false,
};
export function utilReducer(state = initialState, action) {
  switch (action.type) {
    case types.MENU_TOGGLE:
      return {
        ...state,
        menuOpen: action.payload,
      };
    default:
      return state;
  }
}
