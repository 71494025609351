import * as types from "../actions/actionTypes";

const iniitialState = {
  modalOpenConnectk4b: false,
  modalOpenConnecting: false,
  modalOpenGoLiveRequest: false,
  modalOpenFeedback: false,
};

export const modalsReducer = (state = iniitialState, action) => {
  switch (action.type) {
    case types.OPEN_CONNECT_K4B_MODAL:
      return {
        ...state,
        modalOpenConnectk4b: action.payload,
      };
    case types.OPEN_CONNECTING_K4B_MODAL:
      return {
        ...state,
        modalOpenConnecting: action.payload,
      };
    case types.OPEN_GO_LIVE_REQUEST:
      return {
        ...state,
        modalOpenGoLiveRequest: action.payload,
      };
    case types.OPEN_FEEDBACK_MODAL:
      return {
        ...state,
        modalOpenFeedback: action.payload,
      };
    default:
      return state;
  }
};
//
