import * as types from "../actions/actionTypes";
const initialState = {
  isLoggedIn: false,
  results: [],
  loading: false,
  token: "",
  status: false,
  newVerify: "",
  message: "",
  verifyToken: "",
};

export function loginReducer(state = initialState, action) {
  switch (action.type) {
    case types.REGISTER_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case types.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        status: action.status,
        token: action.token,
        results: action.data,
        message: action.message,
      };
    case types.REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
        status: action.status,
        message: action.message,
      };
    case types.UPDATE_CLIENT_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.status,
        message: action.message,
      };
    case types.UPDATE_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        status: action.status,
        message: action.message,
      };
    case types.LOGIN_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        results: action.data,
        loading: false,
        token: action.token,
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        status: action.status,
        message: action.message,
      };
    case types.RESET_LOGIN_STATE:
      return {
        ...state,
        loading: false,
        message: "",
        status: false,
      };
    case types.SIGN_OUT:
      return {
        ...state,
        isLoggedIn: false,
        results: [],
      };
    default:
      return state;
  }
}
