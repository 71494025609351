import axios from "axios";
import Cookies from "js-cookie";
import { aesDecryption } from "./apiSecurity";
export const urls = {
  // base url
  baseUrl: "https://kudaopenapidashboard-api.inkuda.com",

  // api client
  signup: "/api/APIClient/createaccount",
  login: "/api/APIClient/login",
  resetPassword: "/api/APIClient/resetpassword",
  setPassword: "/api/APIClient/setpassword",
  regeneratekeys: "/api/APIClient/regeneratekeys",
  updateTestWebhookUrl: "/api/APIClient/updatetestwebhookurl",
  updateLiveWebhookUrl: "/api/APIClient/updatelivewebhookurl",
  emailCheck: "/api/APIClient/EmailCheck",
  initiateResetPassword: "/api/APIClient/InitiatePasswordReset",
  completeResetPassword: "/api/APIClient/CompletePasswordReset",
  connectKudaAccount: "/api/APIClient/addaccountnumber",
  goLiveRequest: "/api/APIClient/golive",
  uploadDocument: "/api/APIClient/uploaddocument",
  getClientDocument: "/api/APIClient/getclientdocument",
  updateLive: "/api/APIClient/updatelive",
  updateClient: "/api/APIClient/UpdateClient",
  getK4BAccounts: "/api/APIClient/getkudabusinessaccounts",
  acceptCustomerBusAccOption:
    "/api/APIClient/AcceptCustomerBusinessAccountOption",
  verifyOTP: "/api/APIClient/VefifyOTP",
  generateApiToken: "/api/APIClient/GenerateClientApiKey",
  sendEmail: "/api/APIClient/SendEmail",
  updateTokenExpiry: "/api/APIClient/UpdateClientTokenExpiry",
  getTokenExpiry: "/api/APIClient/GetClientTokenExpiryTime",
  updateTestBillWebhookUrl: "/api/APIClient/UpdateTestBillWebHookUrl",
  updateLiveBillWebhookUrl: "/api/APIClient/UpdateLiveBillWebHookUrl",
  loginSendOtp: "/api/APIClient/SendOtp",
  validateOtp: "/api/APIClient/ValidateOTP",

  // dashboard APIs
  getFilteredMainAccountTransactions:
    "/api/Dashboard/getfilteredmainaccounttransactions",
  getMainAccountTransactions: "/api/Dashboard/getmainaccounttransactions",
  getSubAccountTransactions: "/api/Dashboard/getsubaccounttransactions",
  getFilteredSubaccountsTransactions:
    "/api/Dashboard/getfilteredsubaccounttransactions",
  getSubAccounts: "/api/Dashboard/getsubaccounts",
  createSubaccount: "/api/Dashboard/createsubaccount",
  getSubaccountsRevenue: "/api/Dashboard/getsubaccountrevenue",
  getSubaccountsCreated: "/api/Dashboard/getsubaccountcreated",
  getSubAccountsInflow: "/api/Dashboard/getsubaccountinflow",
  getSubAccountsOutflow: "/api/Dashboard/getsubaccountoutflow",
  getBalance: "/api/Dashboard/getbalance",
  getStatement: "/api/Dashboard/getstatement",
  getTransactionLimit: "/api/Dashboard/GetTransactionLimit",
  setTransactionLimit: "/api/Dashboard/setdailyinterbanklimit",
  getVirtualTransactionLimit:
    "/api/Dashboard/GetVirtualAccountTransactionLimit",
  setVirtualTransactionLimit:
    "/api/Dashboard/SetVirtualAccountDailyInterbankLimit",
  getMainAccountTransactionInflow: "/api/Dashboard/getmainaccountinflow",
  getMainAccountTransactionOutflow: "/api/Dashboard/getmainaccountoutflow",
  getVirtualAccountBalance: "/api/Dashboard/getvirtualaccountbalance",
  searchSubAccount: "/api/Dashboard/SearchSubAccount",

  // test console
  testConsole: "/api/TestConsole/test",

  // cards APIs
  checkCardAccessPermission: "/api/APIClient/CheckCardAccessPermission",
  checkCardApprovalStatus: "/api/APIClient/CheckCardDesignApprovalStatus",
  requestCard: "/api/Dashboard/RequestCard",
  fetchCards: "/api/Dashboard/GetCustomerCards",
  downloadGuidlines: "/api/ApiClient/GetCardDesignGuide",
};

const userToken = Cookies.get("userToken");
const token = aesDecryption(
  `${userToken}`,
  `${process.env.REACT_APP_ENCRYPTION_KEY}`
);

export const AuthAxios = axios.create({
  baseURL: urls.baseUrl,
  headers: {
    Authorization: `Bearer ${token}`,
    "X-Frame-Options": "DENY",
  },
});

// https://kudaopenapidashboard-api.inkuda.com

// https://kuda-openapidashboard-uat.kudabank.com

// https://kudaopenapidashboard-api.azurewebsites.net
