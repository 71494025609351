import * as types from "../actions/actionTypes"

const initialState = {
    loading: false,
    status: "",
    message: ""
}

export default function WebhooksReducer (state = initialState, action ) {
    switch (action.type) {
        case types.UPDATE_LIVE_WEBHOOK_URL_API_CALL_INPROGRESS:
        case types.UPDATE_TEST_WEBHOOK_URL_API_CALL_INPROGRESS:
            return {
                ...state,
                loading: true
            }
    case types.UPDATE_LIVE_WEBHOOK_URL_API_CALL_FAILURE:
    case types.UPDATE_TEST_WEBHOOK_URL_API_CALL_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.message
      }
      case types.UPDATE_LIVE_WEBHOOK_URL_API_CALL_SUCCESS:
      case types.UPDATE_TEST_WEBHOOK_URL_API_CALL_SUCCESS:
        return {
            ...state,
            loading: false,
            status: action.data.status,
            message: action.data.message
        }
        default:
            return state
    }
}