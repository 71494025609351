import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import configureStore from "./redux/ConfigureStore";
import { Provider } from "react-redux";
// import { loadConfig } from "./utils/appInit";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { datadogRum } from "@datadog/browser-rum";
import { urls } from "./api/urls";

import { loadState, saveState } from "./redux/reducers/cookiesStorage";
import throttle from "lodash/throttle";
import PreLoader from "./screens/common/PreLoader";
import ErrorBoundary from "./screens/common/ErrorBoundary";

const App = lazy(() => import("./App"));
const persistedState = loadState();
const store = configureStore(persistedState);

store.subscribe(
  throttle(() => {
    saveState({
      login: store.getState().login,
    });
  }, 1000)
);

// loadConfig();
Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  release: window.DD_VERSION,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.9,
});

datadogRum.init({
  applicationId: window.DATADOG_APPLICATION_ID,
  clientToken: window.DATADOG_CLIENT_TOKEN,
  site: "datadoghq.com",
  service: window.DD_SERVICE,
  env: window.DD_ENV,
  version: window.DD_VERSION,
  sampleRate: 90,
  trackInteractions: true,
  allowedTracingOrigins: [urls.baseUrl],
});

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<PreLoader />}>
      <ErrorBoundary>
        <Router>
          <App />
        </Router>
      </ErrorBoundary>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
