import Cookies from "js-cookie";

export const getReference = () => {
  let text = "";
  let possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.=";
  for (let i = 0; i < 15; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
};

export const hexToBase64 = (str) => {
  return btoa(
    String.fromCharCode.apply(
      null,
      str
        .replace(/\r|\n/g, "")
        .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
        .replace(/ +$/, "")
        .split(" ")
    )
  );
};

export const base64ToHex = (str) => {
  for (
    var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = [];
    i < bin.length;
    ++i
  ) {
    var tmp = bin.charCodeAt(i).toString(16);
    if (tmp.length === 1) tmp = "0" + tmp;
    hex[hex.length] = tmp;
  }
  return hex.join("");
};

export const fallbackCopyTextToClipboard = (text) => {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.style.position = "fixed"; //avoid scrolling to bottom
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
};

export const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {},
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
};

export function getYesterday() {
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  let formattedyesterday = yesterday.toISOString().slice(0, 10);
  localStorage.setItem("yesterday", formattedyesterday);
}

export function getToday() {
  let today = new Date();
  today.setDate(today.getDate());
  let formattedToday = today.toISOString().slice(0, 10);
  localStorage.setItem("today", formattedToday);
}
export const getHumanDate = (isoformat) => {
  var readable = new Date(isoformat); // When we pass the ISO format to the JS Date constructor, the return is "Fri Jul 04 2014 21:06:08 GMT-0400 (Eastern Daylight Time)"
  var m = readable.getMonth(); // returns 6 (note that this number is one less than the number of the month in isoformat)
  var d = readable.getDate(); // returns 15
  var y = readable.getFullYear(); // returns 2012
  // we define an array of the months in a year
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  // we get the text name of the month by using the value of m to find the corresponding month name
  var mlong = months[m];
  return mlong + " " + d + ", " + y;
};

export function tokenHasExpired() {
  const tokenExpiresIn = Cookies.get("tokenExpiry");
  const currentTime = Date.now();
  // if (!tokenExpiresIn) {
  //   return;
  // }
  if (currentTime < tokenExpiresIn) {
    return true;
  }
  if (currentTime > tokenExpiresIn) {
    Cookies.remove("tokenExpiry");
    // Cookies.remove("userToken");
    return false;
  }
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

export function formatCurrency(value) {
  return new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(value);
}


getYesterday();
getToday();


export function formatMoney(amount) {
  const options = {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: 2,
  };
  // if its a whole, naira amount, leave off the .00
  if (amount % 100 === 0) options.minimumFractionDigits = 0;
  amount = amount / 100;
  const formatter = new Intl.NumberFormat("en-NG", options);
  return formatter.format(amount);
}