import * as types from "../actions/actionTypes";

const initialState = {
    message: "",
    loading: false,
    data: {},
    error: null,
    status: false
}

export default function tradeNameReducer (state = initialState, action) {
    switch (action.type) {
        case types.EDIT_TRADE_NAME_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        case types.EDIT_TRADE_NAME_IN_PROGRESS:
            return {
                ...state,
                loading: true
            };
        case types.EDIT_TRADE_NAME_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.message,
                data: action.data,
                status: action.status
            }
        default:
            return state
    }
}